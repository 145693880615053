import { useEffect, useState } from "react"
import { useAuth } from "@context/AuthContext"
import { languageSelector as ls } from "@covvi/language-selector"
import AllGeneralOptions from "@components/sections/AllGeneralOptions"
import { ClearCard } from "@ui/cards/ClearCard"
import { HeaderCard } from "@ui/cards/HeaderCard"
import KeyValueArrayInfoCard from "@ui/cards/KeyValueArrayInfoCard"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import Spinner from "@ui/spinners/Spinner"
import {
  DigitError,
  FilteredErrors,
  GeneralErrorsObj,
  GeneralGraphOptions,
  ErrorsArray,
  OtherErrorGraph,
  OverviewErrorGraph,
} from "@typesFolder/types"
import { getGeneralErrors } from "@util/firebase/handFunctions"
import ErrorsOverview from "@components/sections/ErrorsOverview"
import { getMedian } from "@util/commonFunctions/getMedian"
import { getMean } from "@util/commonFunctions/getMean"

interface Props {
  pillArray: {
    buttons: { title: string; onClick: () => void }[]
    selected: string
    setSelected: Function
  }
}

export const AllErrorsOverview = ({ pillArray }: Props) => {
  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth(),
    d = date.getDate()
  const { profile } = useAuth()
  const [sampleSize, setSampleSize] = useState(0)
  const [allErrors, setAllErrors] = useState<GeneralErrorsObj[]>()
  const [graphOptions, setGraphOptions] = useState<GeneralGraphOptions>({
    showingRandD: false,
    showingLoaner: true,
    showingDemo: true,
    showingCustomer: true,
    showingLeft: true,
    showingRight: true,
    showingSmall: true,
    showingMedium: true,
    showingLarge: true,
    showing105: true,
    showing106: true,
    showing107: true,
    usage: [10, 1000000],
    connections: [1, 1000000],
    showingMedian: true,
    timePeriod: "allTime",
    by_date: [0, new Date(y, m, d + 1).getTime().valueOf()],
  })
  const [filteredErrors, setFilteredErrors] = useState<FilteredErrors>()
  const [totalWarning, setTotalWarning] = useState(0)
  const [totalSerious, setTotalSerious] = useState(0)

  const infoCard = [
    { title: ls.getText("hand_sample_size"), value: sampleSize.toString() },
    {
      title: ls.getText("thermal_current_trips"),
      value: totalWarning.toString(),
    },
    {
      title: ls.getText("wiper_sensor_faults"),
      value: totalSerious.toString(),
    },
  ]

  useEffect(() => {
    getGeneralErrors(profile!).then(setAllErrors)
  }, [])

  useEffect(() => {
    if (!allErrors) {
      return
    }

    const digitErrors = {
      sensorFault: 0,
      thermalTrip: 0,
      invalidLimits: 0,
      wiperFault: 0,
      currentTrip: 0,
      unknown: 0,
    }

    const snapsShotErrors = {
      "Errors Overview": {
        bluetoothUser: 0,
        bluetoothInitialisation: 0,
        sensorFault: 0,
        thermalTrip: 0,
        invalidLimits: 0,
        wiperFault: 0,
        currentTrip: 0,
        thumbExtension: 0,
        indexExtension: 0,
        middleExtension: 0,
        ringExtension: 0,
        littleExtension: 0,
        thumbRotation: 0,
      },
      "Other Errors": {
        systemError: 0,
        flashErase: 0,
        flashProgram: 0,
        flashValidate: 0,
        updateAbort: 0,
        unknown: 0,
      },
      "Thumb Errors": JSON.parse(JSON.stringify(digitErrors)),
      "Rotation Errors": JSON.parse(JSON.stringify(digitErrors)),
      "Index Errors": JSON.parse(JSON.stringify(digitErrors)),
      "Middle Errors": JSON.parse(JSON.stringify(digitErrors)),
      "Ring Errors": JSON.parse(JSON.stringify(digitErrors)),
      "Little Errors": JSON.parse(JSON.stringify(digitErrors)),
    }

    let errorsArray = JSON.parse(
      JSON.stringify(snapsShotErrors).replaceAll("0", "[]")
    ) as ErrorsArray

    let errorsToEdit = allErrors
    let totalWarningsTemp = 0
    let totalSeriousTemp = 0

    errorsToEdit = errorsToEdit.filter(
      (hand) =>
        hand.entries >= graphOptions.connections[0] && hand.entries <= graphOptions.connections[1]
    )
    errorsToEdit = errorsToEdit.filter((hand) => {
      let maxAbsTime = 0
      hand.handErrors.forEach((error) => error.absTime > maxAbsTime && (maxAbsTime = error.absTime))
      if (maxAbsTime > graphOptions.usage[0] * 3600 && maxAbsTime < graphOptions.usage[1] * 3600) {
        return hand
      } else return false
    })

    if (graphOptions.timePeriod !== "allTime") {
      errorsToEdit = errorsToEdit
        .filter((hand) => {
          return { ...hand, handErrors: hand.handErrors.filter((error, i) => i !== 0) }
        })
        .filter((hand) => hand.handErrors.length > 0)
    }

    if (
      graphOptions.by_date &&
      (graphOptions.by_date[0] !== 0 ||
        graphOptions.by_date[1] !== new Date(y, m, d + 1).getTime().valueOf())
    ) {
      errorsToEdit = errorsToEdit
        .map((hand) => {
          return {
            ...hand,
            handErrors: hand.handErrors.filter(
              (error) =>
                graphOptions.by_date &&
                error.earliestDate > graphOptions.by_date[0] &&
                error.earliestDate < graphOptions.by_date[1]
            ),
          }
        })
        .filter((hand) => hand.handErrors.length > 0)
    }

    if (!graphOptions.showingLeft)
      errorsToEdit = errorsToEdit.filter((hand) => !(hand.handId.charAt(3) === "L"))
    if (!graphOptions.showingRight)
      errorsToEdit = errorsToEdit.filter((hand) => !(hand.handId.charAt(3) === "R"))
    if (!graphOptions.showingSmall)
      errorsToEdit = errorsToEdit.filter((hand) => !(hand.handId.charAt(4) === "S"))
    if (!graphOptions.showingMedium)
      errorsToEdit = errorsToEdit.filter((hand) => !(hand.handId.charAt(4) === "M"))
    if (!graphOptions.showingLarge)
      errorsToEdit = errorsToEdit.filter((hand) => !(hand.handId.charAt(4) === "L"))
    //if()//
    if (!graphOptions.showingRandD)
      errorsToEdit = errorsToEdit.filter((hand) => !hand.handId.includes("RD1"))
    if (!graphOptions.showingLoaner)
      errorsToEdit = errorsToEdit.filter((hand) => !hand.handId.includes("LN1"))
    if (!graphOptions.showingDemo)
      errorsToEdit = errorsToEdit.filter((hand) => !hand.handId.includes("DH1"))
    if (!graphOptions.showingCustomer)
      errorsToEdit = errorsToEdit.filter((hand) => !hand.handId.includes("CV1"))

    errorsToEdit.forEach((hand, i) => {
      const handErrors = snapsShotErrors as FilteredErrors
      let earliestError = hand.handErrors[0].earliestDate
      let latestError = hand.handErrors[0].earliestDate
      hand.handErrors.forEach((error) => {
        if (error.earliestDate < earliestError) {
          earliestError = error.earliestDate
        }
        if (error.earliestDate > latestError) {
          latestError = error.earliestDate
        }
        if (error.type === 1) {
          handErrors["Other Errors"].systemError += 1
        } else if (error.type === 2 && error.code === 1) {
          handErrors["Errors Overview"].bluetoothInitialisation += 1
        } else if (error.type === 2 && error.code === 2) {
          handErrors["Errors Overview"].bluetoothUser += 1
        } else if ([3, 4, 5, 6, 7, 8].includes(error.type)) {
          let motorError:
            | "Thumb Errors"
            | "Index Errors"
            | "Middle Errors"
            | "Ring Errors"
            | "Little Errors"
            | "Rotation Errors" = "Thumb Errors"
          if (error.type === 3) {
            handErrors["Errors Overview"].thumbExtension += 1
            motorError = "Thumb Errors"
          } else if (error.type === 4) {
            handErrors["Errors Overview"].indexExtension += 1
            motorError = "Index Errors"
          } else if (error.type === 5) {
            handErrors["Errors Overview"].middleExtension += 1
            motorError = "Middle Errors"
          } else if (error.type === 6) {
            handErrors["Errors Overview"].ringExtension += 1
            motorError = "Ring Errors"
          } else if (error.type === 7) {
            handErrors["Errors Overview"].littleExtension += 1
            motorError = "Little Errors"
          } else if (error.type === 8) {
            handErrors["Errors Overview"].thumbRotation += 1
            motorError = "Rotation Errors"
          }
          switch (error.code) {
            case 1:
              totalSeriousTemp += 1
              handErrors[motorError].sensorFault += 1
              return (handErrors["Errors Overview"].sensorFault += 1)
            case 2:
              totalWarningsTemp += 1
              handErrors[motorError].thermalTrip += 1
              return (handErrors["Errors Overview"].thermalTrip += 1)
            case 3:
              handErrors[motorError].invalidLimits += 1
              return (handErrors["Errors Overview"].invalidLimits += 1)
            case 4:
              totalSeriousTemp += 1
              handErrors[motorError].wiperFault += 1
              return (handErrors["Errors Overview"].wiperFault += 1)
            case 5:
              totalWarningsTemp += 1
              handErrors[motorError].currentTrip += 1
              return (handErrors["Errors Overview"].currentTrip += 1)
            default:
              return
          }
        } else if (error.type === 9) {
          handErrors["Other Errors"].flashErase += 1
        } else if (error.type === 10) {
          handErrors["Other Errors"].flashProgram += 1
        } else if (error.type === 11) {
          handErrors["Other Errors"].flashValidate += 1
        } else if (error.type === 12) {
          handErrors["Other Errors"].updateAbort += 1
        } else {
          handErrors["Other Errors"].unknown += 1
        }
      })

      Object.entries(handErrors).forEach(([sectionTitle, section], i) =>
        Object.entries(section).forEach(([paramTitle, parameter], i) => {
          let value = parameter as number
          if (graphOptions.timePeriod !== "allTime") {
            value = Math.round((value / (latestError - earliestError)) * graphOptions.timePeriod)
          }
          ;(
            errorsArray[sectionTitle as keyof ErrorsArray][
              paramTitle as keyof (OverviewErrorGraph | OtherErrorGraph | DigitError)
            ] as number[]
          ).push(value)
        })
      )
    })

    let filtered = JSON.parse(JSON.stringify(snapsShotErrors))

    Object.entries(errorsArray).forEach(([sectionTitle, section], i) =>
      Object.entries(section).forEach(([paramTitle, parameter], i) => {
        let average: number = graphOptions.showingMedian
          ? getMedian(parameter as number[])
          : getMean(parameter as number[])
        ;(filtered[sectionTitle as keyof FilteredErrors][
          paramTitle as keyof (OverviewErrorGraph | OtherErrorGraph | DigitError)
        ] as number) = average
      })
    )
    setTotalWarning(totalWarningsTemp)
    setTotalSerious(totalSeriousTemp)
    setSampleSize(errorsToEdit.length)
    setFilteredErrors(filtered)
  }, [allErrors, graphOptions])

  return (
    <ClearCard>
      <div className="pb-5">
        <HeaderCard>
          <div className="flex-1 space-y-[20px]">
            <PillButtonArray
              pillArray={pillArray.buttons}
              selected={pillArray.selected}
              setSelected={pillArray.setSelected}
            />
            <KeyValueArrayInfoCard array={infoCard} whiteBackground={true} />
          </div>
          <AllGeneralOptions
            graphOptions={graphOptions}
            setGraphOptions={setGraphOptions}
            optionType="Errors"
          />
        </HeaderCard>
        {filteredErrors ? <ErrorsOverview filteredErrors={filteredErrors} /> : <Spinner />}
      </div>
    </ClearCard>
  )
}
