import { languageSelector as ls } from "@covvi/language-selector"
import CVCardBody from "@ui/sections/CVCardBody"

export const NonAccreditationCard = () => {
  return (
    <CVCardBody roundedBottom={true} roundedTop={true}>
      <div className="p-8">
        <div className="text-l">{ls.getText("non_accreditation_1")}</div>
        <div className="text-l mt-8">
          {ls.getText("non_accreditation_2")} customerservice@covvi.com
        </div>
      </div>
    </CVCardBody>
  )
}
