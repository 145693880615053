import { getCovviUids, setCovviUids } from "./userFunctions"
import { firestore, storage, functions } from "./firebase"
import {
  ref,
  listAll,
  uploadBytes,
  deleteObject,
  getBlob,
  StorageReference,
} from "firebase/storage"
import {
  Config,
  GeneralErrorsObj,
  ProfileData,
  ErrorCodeData,
  ErrorAndTime,
  HandFirestoreData,
} from "@typesFolder/types"
import {
  DataCounter,
  DataGrips,
  DataLimits,
  DataTriggers,
  DataUsage,
  ShortSerialStats,
  SingleHandStats,
  StatisticsWithConfigInfo,
  StatsPoss,
} from "@typesFolder/statsTypes"
import {
  query,
  collection,
  getDocs,
  where,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  setDoc,
} from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import { languageSelector as ls } from "@covvi/language-selector"
import { getStatisticsSchema } from "@covvi/common-functions"

export const getListOfHands = (profile: ProfileData) => {
  return new Promise<string[]>((resolve, reject) => {
    if (["Customer Service Team Member", "Tech Team Member", "Admin"].includes(profile.role)) {
      const getConfigHistory = new Promise<string[]>((resolve, reject) => {
        listAll(ref(storage, "ConfigHistory"))
          .then((result) => {
            let hands: string[] = []
            result.prefixes.forEach((folder) => {
              const handName = folder.fullPath.split("/")[1]
              if (
                handName !== "undefined" &&
                handName !== "DEMOHAND" &&
                handName !== "VIRTUAL HAND" &&
                handName.length === 16
              ) {
                hands.push(handName)
              }
            })

            resolve(hands)
          })
          .catch(function (error) {
            reject(error)
          })
      })

      const getLiveConfigs = new Promise<string[]>((resolve, reject) => {
        listAll(ref(storage, "LiveConfig"))
          .then((result) => {
            let hands: string[] = []
            result.items.forEach((file) => {
              const handName = file.fullPath.split(".")[0].split("/")[1]

              if (
                handName !== "undefined" &&
                handName !== "DEMOHAND" &&
                handName !== "VIRTUAL HAND" &&
                handName.length === 16
              ) {
                hands.push(handName)
              }
            })
            resolve(hands)
          })
          .catch(function (error) {
            reject(error)
          })
      })

      Promise.all<string[]>([getConfigHistory, getLiveConfigs])
        .then((allHands) => {
          const allUniqueHands = [...allHands[0], ...allHands[1]].filter(
            (v, i, a) => a.indexOf(v) === i
          )
          resolve(allUniqueHands)
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      reject("insufficient privilege")
    }
  })
}

export const getMyUsersHands = (profile: ProfileData) => {
  return new Promise<string[]>(async (resolve, reject) => {
    let associatedHands: string[] = []
    profile.associated_hands &&
      (associatedHands = [...associatedHands, ...profile.associated_hands])
    profile?.associated_users
      ? profile.associated_users.forEach(async (user, i) => {
          await getDoc(doc(firestore, "Users", user))
            .then((userData) => {
              if (userData.exists() && userData.data()?.associated_hands) {
                associatedHands = [...associatedHands, ...userData.data()?.associated_hands]
              }
              if (profile?.associated_users?.length === i + 1) {
                resolve([...new Set(associatedHands)])
              }
            })
            .catch(() => {
              profile?.associated_users?.length === i + 1 && resolve([...new Set(associatedHands)])
            })
        })
      : resolve(associatedHands)
  })
}

const refToObj = (ref: StorageReference, isLive?: true) => {
  return new Promise<Config>((resolve, reject) => {
    getBlob(ref)
      .then(async (blob) => await blob.text())
      .then(async (json) => await JSON.parse(json))
      .then((config) => {
        resolve({
          ...config,
          setBy: config.setBy || "Unknown",
          name: config.name || (isLive && "Live Config") || "Unknown",
        })
      })
      .catch(() => reject("NONE"))
  })
}

export const getHandConfigs: (forHand: string, profile: ProfileData) => Promise<Config[]> = (
  forHand,
  profile
) => {
  return new Promise(async (resolve, reject) => {
    let acceptableUids: string[] = [profile.uid, "Unknown"]
    const isCovvi = [
      "Admin",
      "Tech Team Member",
      "Sales Team Member",
      "Customer Service Team Member",
    ].includes(profile.role)
    const ordered = (configs: Config[]) =>
      configs.sort((a, b) => {
        if (isNaN(Date.parse(a.date.toString())) && isNaN(Date.parse(b.date.toString()))) {
          return 0
        } else if (isNaN(Date.parse(b.date.toString()))) {
          return -1
        } else if (isNaN(Date.parse(a.date.toString()))) {
          return 1
        } else
          return Date.parse(b.date.toString()).valueOf() - Date.parse(a.date.toString()).valueOf()
      })
    if (!isCovvi) {
      let covviUids: string[] = getCovviUids()
      if (!covviUids.length) {
        covviUids = await setCovviUids()
      }
      acceptableUids = acceptableUids.concat(covviUids)
      profile.clinician && acceptableUids.push(profile.clinician)
      if (profile.associated_users) {
        acceptableUids = acceptableUids.concat(profile.associated_users)
      }
    }
    const configHistoriesRefs = await listAll(ref(storage, "ConfigHistory/" + forHand)).then(
      (result) => result.items
    )
    const configHistories = await Promise.all(
      configHistoriesRefs.map((configRef) => refToObj(configRef))
    ).then((res) =>
      isCovvi
        ? ordered(res)
        : ordered(res.filter((config) => acceptableUids.includes(config.setBy)))
    )
    const liveConfig: Config | false = await refToObj(
      ref(storage, "LiveConfig/" + forHand + ".json"),
      true
    )
      .then((liveConfig) => {
        return liveConfig
      })
      .catch(() => {
        return false
      })
    liveConfig
      ? resolve([{ ...liveConfig, isLive: true }, ...configHistories])
      : resolve(configHistories)
  })
}

export const getHandData: (serialNumber: string) => Promise<HandFirestoreData | undefined> = (
  serialNumber
) => {
  return new Promise((resolve, reject) => {
    getDoc(doc(firestore, "Hands", serialNumber))
      .then((data) => {
        resolve(data.data())
      })
      .catch((e) => {
        reject(`${e}, - Failed to fetch hand data`)
      })
  })
}

export const getFittingDate: (serialNumber: string) => Promise<string> = (serialNumber) => {
  return new Promise((resolve, reject) => {
    getHandData(serialNumber)
      .then((handData) => {
        if (
          handData?.connection_events === undefined ||
          handData?.connection_events[handData.connection_events.length - 1].type !==
            "First connection since service"
        ) {
          resolve(ls.getText("Unknown Date"))
        } else {
          let date = new Date(
            handData?.connection_events[handData.connection_events.length - 1].date.seconds * 1000
          ).toISOString()

          resolve(date)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const uploadConfigHistory = (
  {
    config,
    configVersion,
    firmwareVersion,
    serialNumber,
    name,
    setBy,
    userGrips,
    archivedBy,
    userAppVersion,
    configImportedFrom,
  }: Config,
  profile: ProfileData
) => {
  return new Promise<true>(async (resolve, reject) => {
    const data = {
      name,
      config,
      firmwareVersion,
      configVersion,
      serialNumber,
      setBy,
      userGrips,
      archivedBy,
      date: new Date(),
      userAppVersion,
      configImportedFrom,
    }
    const upload = (data: Config) => {
      return new Promise((resolve, reject) => {
        const blob = new Blob(
          [
            JSON.stringify({
              ...data,
              date: new Date().toISOString().replace("T", " ").split(".")[0],
            }),
          ],
          { type: "application/json" }
        )
        const storageRef = ref(storage, `ConfigHistory/${serialNumber}/${name}.json`)
        uploadBytes(storageRef, blob)
          .then(() => resolve(true))
          .catch(() => reject("error"))
      })
    }

    const overwritableUids: string[] | undefined = profile.associated_users
      ? [profile.uid, ...profile.associated_users]
      : undefined
    const existingConfigRef = ref(storage, `ConfigHistory/${serialNumber}/${name}.json`)
    const existingConfig: Config | false = await refToObj(existingConfigRef)
      .then(async () => await refToObj(existingConfigRef))
      .catch(() => false)
    if (existingConfig === false) {
      upload(data)
        .then(() => resolve(true))
        .catch(() => reject("error"))
    } else if (
      overwritableUids?.includes(existingConfig.setBy) ||
      ["Admin", "Tech Team Member"].includes(profile.role)
    ) {
      upload({
        ...existingConfig,
        name: `ARCHIVED_${Date.now().toString()}_${existingConfig.name}`,
        archivedBy: profile.uid,
      })
        .then(() => upload(data))
        .then(() => resolve(true))
        .catch(() => reject("error"))
    } else {
      reject("error")
    }
  })
}

export const deleteConfig = (config: Config, serialNumber: string, profile: ProfileData) => {
  return new Promise((resolve, reject) => {
    if (config.setBy !== profile.uid && !["Admin", "Tech Team Member"].includes(profile.role)) {
      reject("Insufficient Permission")
      return
    }
    if (config.name && !config.name.startsWith("ARCHIVED")) {
      const configToArchive = {
        config: config.config,
        configVersion: config.configVersion?.toString() || ls.getText("Unknown"),
        firmwareVersion: config.firmwareVersion || ls.getText("Unknown"),
        serialNumber: serialNumber,
        name: `ARCHIVED_${Date.now().toString()}_${config.name}`,
        setBy: config.setBy,
        userGrips: config.userGrips && config.userGrips,
        archivedBy: profile.uid,
        date: config.date || new Date(),
        userAppVersion: config.userAppVersion || ls.getText("Unknown"),
      }
      uploadConfigHistory(configToArchive, profile)
        .then((res) => {
          resolve(res)
          const fileToDeleteRef = ref(storage, `ConfigHistory/${serialNumber}/${config.name}.json`)
          deleteObject(fileToDeleteRef)
            .then(() => resolve("Config Deleted"))
            .catch((e) => reject("Error Deleting Config"))
        })
        .catch((e) => reject("Error Archiving Config"))
    } else {
      deleteObject(ref(storage, `ConfigHistory/${serialNumber}/${config.name}.json`))
        .then(() => resolve("Config Deleted"))
        .catch((e) => reject("Error Deleting Config"))
    }
  })
}

export const getStats = ({
  handId,
  role,
  uid,
  associatedUsers,
}: {
  handId: string
  role: string
  uid: string
  associatedUsers?: string[]
}) => {
  return new Promise<SingleHandStats>((resolve, reject) => {
    const getAll = ["Admin", "Tech Team Member", "Customer Service Team Member"].includes(role)
    const docRef = getAll
      ? collection(firestore, `Hands/${handId}/AppStatistics`)
      : query(
          collection(firestore, `Hands/${handId}/AppStatistics`),
          where("set_by", "in", associatedUsers ? [...associatedUsers.slice(0, 28), uid] : [uid])
        )
    getDocs(docRef)
      .then((querySnapshot) => {
        let handStats = new Map<string, StatisticsWithConfigInfo>()
        let snapShotStats = querySnapshot.docs
        const first = getAll ? false : snapShotStats.shift()
        snapShotStats.forEach((statSnapshot) => {
          let snapshotEdited = statSnapshot.data()

          if (snapshotEdited["data_grips"] === undefined) {
            snapshotEdited["data_grips"] = {
              tripod: 0,
              power: 0,
              trigger: 0,
              precision_open: 0,
              precision_closed: 0,
              key: 0,
              finger_point: 0,
              mouse: 0,
              column: 0,
              relaxed: 0,
              glove: 0,
              finger_tap: 0,
              phone: 0,
              rock: 0,
              user_grip_1: 0,
              user_grip_2: 0,
              user_grip_3: 0,
              user_grip_4: 0,
              user_grip_5: 0,
              user_grip_6: 0,
            }
          }

          Object.entries({
            ...getStatisticsSchema(105),
            ...getStatisticsSchema(106),
            ...getStatisticsSchema(107),
          }).forEach((section) => {
            section[0] !== "data_limits" &&
              section[1].options.forEach((stat) => {
                if ("title" in stat && statSnapshot.data()[section[0]]) {
                  const thisVal = parseInt(statSnapshot.data()[section[0]][stat.title])
                  if (!first) {
                    snapshotEdited[section[0]][stat.title] = thisVal | 0
                  } else {
                    const initialVal = parseInt(first!.data()[section[0]][stat.title])
                    if (thisVal > initialVal) {
                      snapshotEdited[section[0]][stat.title] = thisVal - initialVal
                    } else if (thisVal <= initialVal) {
                      snapshotEdited[section[0]][stat.title] = thisVal
                    } else {
                      snapshotEdited[section[0]][stat.title] = 0
                    }
                  }
                }
              })
          })
          handStats.set(statSnapshot.id, snapshotEdited as StatisticsWithConfigInfo)
        })
        resolve({ [handId]: handStats })
      })
      .catch((e) => {
        console.error("Failed to get stats", e)
        reject()
      })
  })
}

export const getLatestErrors = ({
  handId,
  uid,
  role,
  associatedUsers,
}: {
  handId: string
  uid: string
  role: string
  associatedUsers?: string[]
}) => {
  return new Promise<ErrorCodeData[]>((resolve, reject) => {
    const getAll = ["Admin", "Tech Team Member", "Customer Service Team Member"].includes(role)
    const docRef = getAll
      ? collection(firestore, `Hands/${handId}/HandErrors`)
      : query(
          collection(firestore, `Hands/${handId}/HandErrors`),
          where("set_by", "in", associatedUsers ? [...associatedUsers.slice(0, 28), uid] : [uid])
        )
    getDocs(docRef)
      .then((querySnapshot) => {
        let errorMap = new Map()
        if (getAll) {
          querySnapshot.forEach((errorSnap) => {
            let snapshotErrors = Object.values(errorSnap.data()) as ErrorCodeData[]
            snapshotErrors.pop()
            snapshotErrors.forEach((error) =>
              errorMap.set(`AT${error.absTime}T${error.type}C${error.code}`, error)
            )
          })
          resolve(Array.from(errorMap.values()))
        } else if (querySnapshot.docs.length) {
          let snapshotError = Object.values(
            querySnapshot.docs[querySnapshot.docs.length - 1].data() as ErrorCodeData[]
          )
          snapshotError.pop()
          resolve(snapshotError)
        }
      })
      .catch((e) => {
        console.error("failed to get errors", e)
        reject(e)
      })
  })
}

const updateHandField = (hand: string, attributeCollection: string, attribute: string) => {
  return new Promise((resolve) => {
    getDocs(collection(firestore, `Hands/${hand}/${attributeCollection}`))
      .then((querySnapshot) => {
        if (querySnapshot.size) {
          const timeStamps = Array.from(querySnapshot.docs)
            .map((doc) => doc.id)
            .sort((a, b) => parseInt(b) - parseInt(a))
          setDoc(
            doc(firestore, "Hands", hand),
            {
              [attribute]: timeStamps[0],
            },
            { merge: true }
          )
          resolve(true)
        } else resolve(false)
      })
      .catch((e) => resolve(false))
  })
}

export const getGeneralStats = (profile: ProfileData) => {
  return new Promise<ShortSerialStats>(async (resolve, reject) => {
    const startTime = Date.now()
    let handsToIgnore = [
      // default hand & Dave's default
      "0000",
      "1234",
      // cycle tested hands
      "0024",
      "1158",
      "1128",
      "1343",
      "1320",
    ]
    let allHandsObj: SingleHandStats = {}
    let shortSerialStats: ShortSerialStats

    let latest: StorageReference | undefined
    let hands: string[] = []

    const replacer = (key: string, value: any) => {
      if (value instanceof Map) {
        return {
          dataType: "Map",
          value: Array.from(value.entries()), // or with spread: value: [...value]
        }
      } else {
        return value
      }
    }

    const reviver = (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
          return new Map(value.value)
        }
      }
      return value
    }

    const mergeHandsBySerial = (statsMapObj: SingleHandStats) => {
      const handsObj: SingleHandStats = {}
      Object.entries(statsMapObj).forEach(([serial, entry], i) => {
        for (const [date, statsObj] of entry) {
          const shortSerial = serial.substring(serial.length - 4, serial.length)
          if (!handsObj[shortSerial]) {
            handsObj[shortSerial] = new Map()
          }
          handsObj[shortSerial].set(date, statsObj)
        }
      })
      return handsObj
    }

    const consolidatedConfigs = (statsMapObj: SingleHandStats) => {
      const returnObj: ShortSerialStats = {}
      Object.entries(statsMapObj).forEach(([serial, entry], i) => {
        const shortSerial = serial.substring(serial.length - 4, serial.length)
        if (!returnObj[shortSerial]) {
          returnObj[shortSerial] = new Map()
        }
        let statsArray = Array.from(entry)
        statsArray.forEach(([date, statsObj], i) => {
          if (i === 0) {
            returnObj[shortSerial].set(date, statsObj)
          } else {
            let currentObj: StatsPoss = {
              data_counter: {},
              data_triggers: {},
              data_limits: {},
              data_usage: {},
              data_grips: {},
            }
            Object.entries(statsObj).forEach(([key, value]) => {
              const keyNamed = key as keyof StatisticsWithConfigInfo
              const prev = statsArray[i - 1][1]
              const cur = statsArray[i][1]
              if (keyNamed === "config_hex" && prev.config_hex !== cur.config_hex) {
                currentObj.config_hex = cur.config_hex
              }
              if (["config_hex", "set_by", "user_grips"].includes(keyNamed)) {
                return
              }
              if (keyNamed === "config_hex" && prev.config_hex !== cur.config_hex) {
                currentObj.config_hex = cur.config_hex
              } else {
                Object.entries(value).forEach(([statsParam, numVal]) => {
                  const statVal = numVal as number
                  switch (keyNamed) {
                    case "data_usage":
                      const dUsage = statsParam as keyof DataUsage
                      if (cur.data_usage[dUsage] !== prev.data_usage[dUsage])
                        currentObj.data_usage[dUsage] = statVal
                      break
                    case "data_grips":
                      const dGrips = statsParam as keyof DataGrips
                      if (cur.data_grips[dGrips] !== prev.data_grips[dGrips])
                        currentObj.data_grips[dGrips] = statVal
                      break
                    case "data_counter":
                      const dCounter = statsParam as keyof DataCounter
                      if (cur.data_counter[dCounter] !== prev.data_counter[dCounter])
                        currentObj.data_counter[dCounter] = statVal
                      break
                    case "data_triggers":
                      const dTriggers = statsParam as keyof DataTriggers
                      if (cur.data_triggers[dTriggers] !== prev.data_triggers[dTriggers])
                        currentObj.data_triggers[dTriggers] = statVal
                      break
                    case "data_limits":
                      const dLimits = statsParam as keyof DataLimits
                      if (cur.data_limits[dLimits] !== prev.data_limits[dLimits])
                        currentObj.data_limits[dLimits] = statVal
                      break
                    default:
                      console.warn("Error:", keyNamed, key, serial)
                      break
                  }
                })
              }
            })
            returnObj[shortSerial].set(date, currentObj)
          }
        })
      })
      return returnObj
    }

    const getInt = (ref: StorageReference) => parseInt(ref.name.split("_")[0])

    await listAll(ref(storage, "CachedStatsSnapshots/")).then((result) =>
      result.items.forEach((ref) => {
        if (!latest || getInt(ref) > getInt(latest)) {
          latest = ref
        }
      })
    )

    if (!latest || (latest && getInt(latest) + 604800000 < Date.now())) {
      // comment this ^^ to bypass stats caching
      await getDocs(query(collection(firestore, `Hands`))).then(
        async (res) =>
          await Promise.all(
            res.docs
              .map((hand) => hand.id)
              .map((hand) => updateHandField(hand, "AppStatistics", "latest_stats"))
          )
      )
      await getDocs(query(collection(firestore, `Hands`), where("latest_stats", "!=", null)))
        .then((querySnapshot) => querySnapshot.forEach((doc) => hands.push(doc.id)))
        .catch((e) => reject(`Couldn't get Stats: ${e}`))

      hands = hands.filter(
        (handId) =>
          !(handId.startsWith("CV1") && handsToIgnore.some((hand) => handId.endsWith(hand)))
      )

      await Promise.all(
        hands.map((hand) =>
          getStats({
            handId: hand,
            role: profile.role,
            uid: profile.uid,
            associatedUsers: profile.associated_users,
          })
        )
      )
        .then((res) =>
          res
            .filter((handStatsObj) => Object.values(handStatsObj)[0].size > 0)
            .forEach((hand) => {
              allHandsObj[Object.keys(hand)[0]] = Object.values(hand)[0]
            })
        )
        .catch((e) => reject(`Couldn't get Stats: ${e}`))

      allHandsObj = mergeHandsBySerial(allHandsObj)
      shortSerialStats = consolidatedConfigs(allHandsObj)

      if (shortSerialStats) {
        resolve(shortSerialStats)
        uploadBytes(
          ref(storage, `CachedStatsSnapshots/${Date.now()}.json`),
          new Blob(
            [
              JSON.stringify(
                {
                  set_by: profile.uid,
                  shortSerialStats: shortSerialStats,
                  date: new Date().toISOString().replace("T", " ").split(".")[0],
                },
                replacer
              ),
            ],
            { type: "application/json" }
          )
        ).then(() => console.log("TIME TAKEN TO COMPLETE:", Date.now() - startTime))
      } else {
        reject(`Couldn't retrieve stats for hands: ${hands}`)
      }
    } else {
      // comment this else block ^^ to bypass stats caching
      await getBlob(latest)
        .then(async (blob) => await blob.text())
        .then(async (json) => await JSON.parse(json, reviver))
        .then((statsSnaphot) => resolve(statsSnaphot.shortSerialStats))
        .catch(() => reject("Error retrieving latest cached stats"))
    }
  })
}

export const getGeneralErrors = (profile: ProfileData) => {
  return new Promise<GeneralErrorsObj[]>(async (resolve, reject) => {
    let allHandsArray: GeneralErrorsObj[] | void = []
    let latest: StorageReference | undefined
    let hands: string[] = []

    const getInt = (ref: StorageReference) => parseInt(ref.name.replace(".json", ""))

    const getHandErrors = (handId: string) => {
      return new Promise<GeneralErrorsObj>((resolve) => {
        const blankResolve = {
          handId,
          handErrors: [],
          entries: 0,
        }

        getDocs(collection(firestore, `Hands/${handId}/HandErrors`))
          .then((handSnapshot) => {
            const handErrors: ErrorAndTime[] = []
            handSnapshot.docs.forEach((snapshot, i) => {
              const errors = Object.values(snapshot.data()) as ErrorAndTime[]
              errors.pop()
              errors.forEach((er) => {
                const existsIndex = handErrors.findIndex(
                  (obj) =>
                    obj.absTime === er.absTime && obj.type === er.type && obj.code === er.code
                )
                if (existsIndex === -1) {
                  handErrors.push({ ...er, earliestDate: parseInt(snapshot.id) })
                } else if (parseInt(snapshot.id) < handErrors[existsIndex].earliestDate) {
                  handErrors[existsIndex].earliestDate = parseInt(snapshot.id)
                }
              })
            })
            handErrors.sort((a, b) => a.earliestDate - b.earliestDate)
            resolve({
              handId,
              handErrors,
              entries: handErrors.length,
            })
          })
          .catch(() => resolve(blankResolve))
      })
    }

    await listAll(ref(storage, "CachedErrorsSnapshots/")).then((result) =>
      result.items.forEach((ref) => {
        if (!latest || getInt(ref) > getInt(latest)) {
          latest = ref
        }
      })
    )

    if (!latest || (latest && getInt(latest) + 604800000 < Date.now())) {
      await getDocs(query(collection(firestore, "Hands"))).then(
        async (res) =>
          await Promise.all(
            res.docs
              .map((hand) => hand.id)
              .map((hand) => updateHandField(hand, "HandErrors", "latest_errors"))
          )
      )
      await getDocs(query(collection(firestore, `Hands`), where("latest_errors", "!=", null)))
        .then((querySnapshot) => querySnapshot.forEach((doc) => hands.push(doc.id)))
        .catch((e) => reject(`Couldn't get Errors: ${e}`))
      allHandsArray = await Promise.all(hands.map((hand) => getHandErrors(hand)))
        .then((res) => res.filter((hand) => hand.entries > 0))
        .catch((e) => reject(`Couldn't get Stats: ${e}`))
      if (allHandsArray) {
        resolve(allHandsArray)
        uploadBytes(
          ref(storage, `CachedErrorsSnapshots/${Date.now()}.json`),
          new Blob(
            [
              JSON.stringify({
                set_by: profile.uid,
                hands_array: allHandsArray,
                date: new Date().toISOString().replace("T", " ").split(".")[0],
              }),
            ],
            { type: "application/json" }
          )
        )
      } else {
        reject(`Couldn't retrieve Errors for hands: ${hands}`)
      }
    } else {
      await getBlob(latest)
        .then(async (blob) => await blob.text())
        .then(async (json) => await JSON.parse(json))
        .then((errorSnap) => resolve(errorSnap.hands_array))
        .catch(() => reject("Error retrieving latest cached stats"))
    }
  })
}

export const addAssociatedHand = (profileUid: string, serialNumberToAdd: string) => {
  return new Promise((resolve, reject) => {
    updateDoc(doc(firestore, "Users", profileUid), {
      associated_hands: arrayUnion(serialNumberToAdd),
    })
      .then(resolve)
      .catch(reject)
  })
}

export const sendConfigVersion100ProbableErrorEmail = (
  profile: ProfileData,
  serialNumber: string,
  configHex: string,
  remoteAssistRoom?: string
) => {
  return new Promise<string>(async (resolve, reject) => {
    const config100email = httpsCallable(functions, "configVersion100ProbableError")
    let emailData = {
      role: profile.role,
      name: `${profile.first_name} ${profile.last_name}`,
      emailAddress: profile.email_address,
      userUid: profile.uid,
      serialNumber: serialNumber,
      remoteAssistRoom: remoteAssistRoom,
      date: new Date().toString(),
      configHex: configHex,
    }

    config100email(emailData)
      .then(() => {
        resolve("Config version 100 probable error email sent.")
      })
      .catch((e) => {
        reject(`${e} - Failed to send config version 100 email`)
      })
  })
}
