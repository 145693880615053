import { useEffect, useState } from "react"
import { languageSelector as ls } from "@covvi/language-selector"
import { getStatisticsSchema, toInt } from "@covvi/common-functions"
import { getGeneralStats } from "@util/firebase/handFunctions"
import { useAuth } from "@context/AuthContext"
import { getMedian } from "@util/commonFunctions/getMedian"
import StatsOverview from "@components/sections/StatsOverview"
import AllGeneralOptions from "@components/sections/AllGeneralOptions"
import KeyValueArrayInfoCard from "@ui/cards/KeyValueArrayInfoCard"
import Spinner from "@ui/spinners/Spinner"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import { HeaderCard } from "@ui/cards/HeaderCard"
import { ClearCard } from "@ui/cards/ClearCard"
import { GeneralGraphOptions } from "@typesFolder/types"
import {
  GeneralStatsObj,
  Statistics,
  DataUsage,
  DataGrips,
  DataCounter,
  DataTriggers,
  DataLimits,
  ShortSerialStats,
  StatsPoss,
} from "@typesFolder/statsTypes"

interface Props {
  pillArray: {
    buttons: { title: string; onClick: () => void }[]
    selected: string
    setSelected: Function
  }
}

interface DataObj {
  title: string
  data: { [key: string]: number }
}

export const AllStatsOverview = ({ pillArray }: Props) => {
  const { profile } = useAuth()
  const [rawStats, setRawStats] = useState<ShortSerialStats>()
  const [sampleSize, setSampleSize] = useState<number>(0)
  const [gripUsageData, setGripUsageData] = useState<DataObj>()
  const [gripCloseData, setGripCloseData] = useState<DataObj>()
  const [triggerData, setTriggerData] = useState<DataObj>()
  const [limitData, setLimitData] = useState<DataObj>()
  const [counterData, setCounterData] = useState<DataObj>()
  const [graphOptions, setGraphOptions] = useState<GeneralGraphOptions>({
    showingLoaner: true,
    showingRandD: false,
    showingDemo: true,
    showingCustomer: true,
    showingLeft: true,
    showingRight: true,
    showingSmall: true,
    showingMedium: true,
    showingLarge: true,
    showing105: false,
    showing106: false,
    showing107: true,
    usage: [10, 1000000],
    connections: [1, 1000000],
    showingPassiveUsage: false,
    showingMedian: true,
    timePeriod: 2630000000,
    snapshotTimeDifference: 86400000,
  })

  const infoCard = [
    { title: ls.getText("hand_sample_size"), value: sampleSize.toString() },
    {
      title: `${ls.getText("average_grips_cycled")} (${ls.getText("config_version_name")} 107+)`,
      value: gripCloseData
        ? (
            Math.round(
              Object.entries(gripCloseData.data).reduce((accumulator, currentValue) => {
                return !["powered_on", "idle", "standby"].includes(currentValue[0])
                  ? accumulator + currentValue[1]
                  : accumulator
              }, 0) * 10
            ) / 10
          ).toString()
        : "",
    },
    {
      title: ls.getText("average_triggers_performed"),
      value: triggerData
        ? Math.round(
            Object.values(triggerData.data).reduce((accumulator, currentValue) => {
              return accumulator + (currentValue | 0)
            }, 0)
          ).toString()
        : "",
    },
  ]

  useEffect(() => {
    getGeneralStats(profile!).then((res) => setRawStats(res))
  }, [])

  useEffect(() => {
    if (!rawStats) {
      return
    }
    const {
      showingLoaner,
      showingRandD,
      showingDemo,
      showingCustomer,
      showingLeft,
      showingRight,
      showingSmall,
      showingMedium,
      showingLarge,
      showing105,
      showing106,
      showing107,
      usage,
      connections,
      showingPassiveUsage,
      showingMedian,
      timePeriod,
      snapshotTimeDifference,
    } = graphOptions

    // console.log(graphOptions)

    let rawCopy = { ...rawStats } as ShortSerialStats
    let generalStatsObjArray: GeneralStatsObj[] = []

    const medianStats: {
      data_counter: { [key: string]: number[] }
      data_triggers: { [key: string]: number[] }
      data_limits: { [key: string]: number[] }
      data_grips: { [key: string]: number[] }
      data_usage: { [key: string]: number[] }
    } = {
      data_counter: {},
      data_triggers: {},
      data_limits: {},
      data_grips: {},
      data_usage: {},
    }
    const averageStats: { [key: string]: { [key: string]: number } } = {
      data_counter: {},
      data_triggers: {},
      data_limits: {},
      data_grips: {},
      data_usage: {},
    }

    Object.entries(rawCopy).forEach(
      ([shortSerial, entries]) =>
        !(entries.size > connections[0] && entries.size < connections[1]) &&
        delete rawCopy[shortSerial]
    )

    Object.entries(rawCopy).forEach(([shortSerial, entries]) => {
      const entryArray = Array.from(entries)
      entryArray.forEach(([date, entry], i) => {
        if (!entry.config_hex && i !== 0) {
          let currentEntry = rawCopy[shortSerial].get(date)
          currentEntry &&
            rawCopy[shortSerial].set(date, {
              ...currentEntry,
              config_hex: Array.from(entries)[i - 1][1].config_hex,
            })
        }
      })
    })

    Object.entries(rawCopy).forEach(([shortSerial, statsByDate]) => {
      let handStatsMap = new Map(statsByDate)
      Array.from(handStatsMap).forEach(([date, statsEntry], i) => {
        const configHex = statsEntry.config_hex
        if (!configHex) {
          console.log(shortSerial, "NO CONFIGHEX", date, statsEntry)
          return
        }
        const removeFiltered = (shouldDelete: boolean) => shouldDelete && handStatsMap.delete(date)
        const configVer = toInt(configHex.slice(12, 16))
        const laterality = toInt(configHex.slice(24, 26))
        const size = toInt(configHex.slice(26, 28))
        const owner = configHex.slice(32, 38)

        removeFiltered(
          ![105, 106, 107].includes(configVer) ||
            ![1, 2].includes(laterality) ||
            ![1, 2, 3].includes(size) ||
            !["524431", "4C4E31", "444831", "435631"].includes(owner)
        )

        removeFiltered(
          (!showing105 && configVer === 105) ||
            (!showing106 && configVer === 106) ||
            (!showing107 && configVer === 107) ||
            (!showingLeft && laterality === 1) ||
            (!showingRight && laterality === 2) ||
            (!showingSmall && size === 1) ||
            (!showingMedium && size === 2) ||
            (!showingLarge && size === 3) ||
            (!showingRandD && owner === "524431") ||
            (!showingLoaner && owner === "4C4E31") ||
            (!showingDemo && owner === "444831") ||
            (!showingCustomer && owner === "435631")
        )
      })

      const consolidateStatsSet = (statsMapByDate: Map<string, StatsPoss>): GeneralStatsObj => {
        const statsObj: { [key: string]: { [key: string]: number } } = {
          data_counter: {},
          data_triggers: {},
          data_limits: {},
          data_usage: {},
          data_grips: {},
        }
        const statsMap: [string, StatsPoss][] = Array.from(statsMapByDate)

        const firstHandStats = JSON.parse(JSON.stringify(statsObj))
        const handMaxStats = JSON.parse(JSON.stringify(statsObj))
        let firstDate: number = 0
        let lastDate: number = 0
        let entries: number = statsMap.length

        statsMap.forEach(([date, statsSubmission], i) => {
          Object.entries({
            ...getStatisticsSchema(105),
            ...getStatisticsSchema(106),
            ...getStatisticsSchema(107),
          }).forEach((section) => {
            ;("sub_options" in section[1].options[0]
              ? section[1].options[0].sub_options
              : section[1].options
            ).forEach((stat) => {
              if ("title" in stat && (section[0] as keyof Statistics)) {
                if (firstDate === 0 || parseInt(date) < firstDate) firstDate = parseInt(date)
                if (parseInt(date) > lastDate) lastDate = parseInt(date)
                const thisVal: number =
                  parseInt(
                    statsSubmission[section[0] as keyof Statistics][
                      stat.title as keyof (
                        | DataLimits
                        | DataCounter
                        | DataGrips
                        | DataTriggers
                        | DataUsage
                      )
                    ]
                  ) || 0

                if (handMaxStats[section[0]][stat.title] === undefined) {
                  firstHandStats[section[0]][stat.title] = thisVal
                  handMaxStats[section[0]][stat.title] = thisVal
                } else if (
                  stat.title === "min_battery" &&
                  thisVal < handMaxStats[section[0]][stat.title]
                ) {
                  handMaxStats[section[0]][stat.title] = thisVal
                } else if (thisVal > handMaxStats[section[0]][stat.title]) {
                  handMaxStats[section[0]][stat.title] = thisVal
                } else if (
                  thisVal < handMaxStats[section[0]][stat.title] &&
                  section[0] !== "data_limits"
                ) {
                  const previousVal =
                    statsMap[i - 1][1] &&
                    statsMap[i - 1][1][section[0] as keyof Statistics][
                      stat.title as keyof (
                        | DataLimits
                        | DataCounter
                        | DataGrips
                        | DataTriggers
                        | DataUsage
                      )
                    ]

                  if (previousVal && thisVal > previousVal) {
                    handMaxStats[section[0]][stat.title] =
                      handMaxStats[section[0]][stat.title] - previousVal + thisVal
                  } else {
                    handMaxStats[section[0]][stat.title] =
                      handMaxStats[section[0]][stat.title] + thisVal
                  }
                }
              }
            })
          })
        })

        return {
          handId: shortSerial,
          handMaxStats: entries > 1 && handMaxStats,
          entries,
          firstHandStats,
          timeDifference: handMaxStats ? lastDate - firstDate : 0,
        }
      }

      const generalObj: GeneralStatsObj = consolidateStatsSet(handStatsMap)

      const hasEntries = generalObj.entries > 0
      const handUsage = generalObj.handMaxStats || generalObj.firstHandStats
      const passesPoweredFilter =
        handUsage.data_usage.powered_on > usage[0] * 3600 &&
        handUsage.data_usage.powered_on < usage[1] * 3600
      const passesTimeDifferenceFilter =
        snapshotTimeDifference === "any" ||
        (typeof snapshotTimeDifference === "number" &&
          generalObj.timeDifference > snapshotTimeDifference)

      if (hasEntries && passesPoweredFilter && passesTimeDifferenceFilter) {
        generalStatsObjArray.push(generalObj)
      }
    })

    generalStatsObjArray.forEach(({ handMaxStats, firstHandStats, timeDifference }, i) => {
      Object.entries({
        ...getStatisticsSchema(105),
        ...getStatisticsSchema(106),
        ...getStatisticsSchema(107),
      }).forEach(([uncheckedTitle, section]) => {
        const title = uncheckedTitle as keyof Statistics
        ;("sub_options" in section.options[0]
          ? section.options[0].sub_options
          : section.options
        ).forEach((stat) => {
          if ("title" in stat && handMaxStats[title]) {
            // const statTitle = stat.title as keyof handMaxStats[title]
            // if (timePeriod !== "allTime" && title !== "data_limits") {
            const sendProcessed = timePeriod !== "allTime" && title !== "data_limits"

            const getVal = (max: number | undefined, first: number | undefined): number => {
              if (timePeriod === "allTime") {
                console.log("LOGGING DEFAULTS")
                return 0
              } else if (max && !first) {
                return max
              } else if (!max && first) {
                return first
              } else if (!max || !first) {
                return 0
              } else {
                return Math.round(((max - first) / timeDifference) * timePeriod)
              }
            }

            let thisVal: number = 0

            const setVal = () => {
              switch (title) {
                case "data_usage":
                  const usage = stat.title as keyof DataUsage
                  thisVal = sendProcessed
                    ? getVal(handMaxStats[title][usage], firstHandStats[title][usage])
                    : handMaxStats[title][usage]
                  return
                case "data_counter":
                  const counter = stat.title as keyof DataCounter
                  thisVal = sendProcessed
                    ? getVal(handMaxStats[title][counter], firstHandStats[title][counter])
                    : handMaxStats[title][counter]
                  return
                case "data_triggers":
                  const triggers = stat.title as keyof DataTriggers
                  thisVal = sendProcessed
                    ? getVal(handMaxStats[title][triggers], firstHandStats[title][triggers])
                    : (handMaxStats[title][triggers] as number)
                  return
                case "data_grips":
                  const grips = stat.title as keyof DataGrips
                  thisVal = sendProcessed
                    ? getVal(handMaxStats[title][grips], firstHandStats[title][grips])
                    : handMaxStats[title][grips]
                  return
                case "data_limits":
                  const limits = stat.title as keyof DataLimits
                  thisVal = handMaxStats[title][limits]
                  return
                default:
                  console.log("SET DEFAULT", title, stat.title)
                  thisVal = 0
                  return
              }
            }

            setVal()

            if (showingMedian) {
              let command = title as
                | "data_usage"
                | "data_grips"
                | "data_triggers"
                | "data_counter"
                | "data_limits"

              if (!medianStats[command][stat.title]) {
                medianStats[command][stat.title] = [thisVal]
              } else {
                medianStats[command][stat.title].push(thisVal)
              }
              if (i === generalStatsObjArray.length - 1) {
                averageStats[title][stat.title] = getMedian(medianStats[command][stat.title])
              }
            } else {
              if (!averageStats[title][stat.title]) {
                averageStats[title][stat.title] = thisVal
              } else {
                averageStats[title][stat.title] = averageStats[title][stat.title] += thisVal
              }
              if (generalStatsObjArray.length > 1 && i === generalStatsObjArray.length - 1) {
                averageStats[title][stat.title] = parseFloat(
                  (averageStats[title][stat.title] / generalStatsObjArray.length).toFixed(2)
                )
              }
            }
          }
        })
      })
    })

    !showingPassiveUsage &&
      delete averageStats.data_usage.powered_on &&
      delete averageStats.data_usage.idle &&
      delete averageStats.data_usage.standby
    setSampleSize(generalStatsObjArray.length)
    setGripUsageData({ title: "Data Usage", data: averageStats.data_usage })
    setGripCloseData({ title: "Data Grips", data: averageStats.data_grips })
    setTriggerData({ title: "Data Triggers", data: averageStats.data_triggers })
    setLimitData({ title: "Data Limits", data: averageStats.data_limits })
    setCounterData({ title: "Data Counter", data: averageStats.data_counter })
  }, [rawStats, graphOptions])

  return (
    <ClearCard>
      <div className="pb-5">
        <HeaderCard>
          <div className="flex-1 space-y-[20px]">
            <PillButtonArray
              pillArray={pillArray.buttons}
              selected={pillArray.selected}
              setSelected={pillArray.setSelected}
            />
            <KeyValueArrayInfoCard array={infoCard} whiteBackground={true} />
          </div>
          <AllGeneralOptions
            graphOptions={graphOptions}
            setGraphOptions={setGraphOptions}
            optionType="Stats"
          />
        </HeaderCard>
        {gripUsageData && gripCloseData && counterData && limitData && triggerData ? (
          <StatsOverview
            dataUsage={gripUsageData}
            dataGrips={gripCloseData}
            dataCounter={counterData}
            dataLimits={limitData}
            dataTriggers={triggerData}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </ClearCard>
  )
}
