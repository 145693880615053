import { useState, useEffect, useRef } from "react"
import { Button, TextField, MenuItem } from "@mui/material"
import { NewTableOptions, Sort, TableData } from "@typesFolder/types"
import { tableSorter } from "@util/commonFunctions/tableSorter"
import { dataToDisplay } from "@util/commonFunctions/tableDataDisplay"
import { languageSelector as ls } from "@covvi/language-selector"
import CVSearchBar from "@ui/inputs/CVSearchBar"
import { PillButtonArray } from "@ui/sections/PillButtonArray"
import CVSubtitle from "@ui/text/CVSubtitle"

interface Props {
  data: TableData[]
  tableOptions: NewTableOptions
}

const Table = ({ data, tableOptions }: Props) => {
  const [search, setSearch] = useState<string>("")
  const [sortedData, setSortedData] = useState<TableData[]>([])
  const [displayData, setDisplayData] = useState<TableData[]>()
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [sorting, setSorting] = useState<Sort | { title: ""; asc: true }>()
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [sortOptions, setSortOptions] = useState<string[]>([])
  const scrollDivRef = useRef<null | HTMLDivElement>(null)

  const scrollToTop = () => {
    scrollDivRef.current &&
      scrollDivRef.current.scroll({
        top: 0,
        behavior: "smooth",
      })
  }

  const onScrollToBottom = () => {
    setDisplayData((search ? searchResults : sortedData).slice(0, (pageNumber + 1) * 10))
    setPageNumber(pageNumber + 1)
  }

  useEffect(() => setSortedData([...data]), [data])

  useEffect(() => {
    if (tableOptions.fieldIds.includes("date") && data[0]) {
      if ("name" in data[0] && data[0].name === "Live Config") {
      } else {
        setSorting({
          title: "date",
          asc: false,
        })
      }
    } else if (tableOptions.fieldIds.includes("absTime")) {
      setSorting({
        title: "Time",
        asc: false,
      })
    }
    let sortOptions: string[] = []
    tableOptions.fieldTitles.forEach((fieldTitle) => {
      sortOptions.push(fieldTitle + " (ASC)")
      sortOptions.push(fieldTitle + " (DESC)")
    })
    setSortOptions(sortOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableOptions])

  useEffect(() => {
    if (search && sortedData) {
      setSearchResults(
        sortedData.filter((item: any) => {
          let doesMatchSearch = false
          tableOptions.searchParams?.forEach((param) => {
            let stringToSearch = dataToDisplay(param, item[param])
            if (
              stringToSearch &&
              stringToSearch.toString().toLowerCase().includes(search.toLowerCase())
            ) {
              doesMatchSearch = true
            }
          })
          return doesMatchSearch
        })
      )
    } else {
      sortedData && setDisplayData(sortedData.slice(0, 10))
      setSearchResults([])
      setPageNumber(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedData, search])

  useEffect(() => {
    scrollToTop()
    if (searchResults.length && search) {
      setDisplayData(searchResults.slice(0, 10))
      setPageNumber(1)
    } else if (search && !searchResults.length) {
      setDisplayData([])
      setPageNumber(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  useEffect(() => {
    if (sorting && sorting.title !== "") {
      let dataCopy = [...data]
      const sorted = dataCopy.sort((a: any, b: any) => {
        return tableSorter(sorting, a, b)
      })
      setSortedData(sorted)
    }
  }, [sorting, data])

  return (
    <div className="w-full h-full flex flex-col overflow-auto">
      {/* <div className="grid grid-cols-2 grid-flow-col md:flex md:flex-row w-full justify-between gap-x-[10px] space-y-[10px] sm:space-y-[23px] md:space-y-0 pt-[10px]"> */}
      <div
        className={`flex flex-col  ${tableOptions.pillArray ? "lg:flex-row" : "md:flex-row"} 
        ${
          tableOptions.pillArray && tableOptions.button ? "xl:flex-row" : ""
        } w-full justify-between`}
      >
        {tableOptions.pillArray && (
          <div className="py-2">
            <PillButtonArray
              pillArray={tableOptions.pillArray.buttons}
              selected={tableOptions.pillArray.selected}
              setSelected={tableOptions.pillArray.setSelected}
            />
          </div>
        )}
        <div
          className={`py-2 ${
            tableOptions.button ? "grid grid-cols-2 gap-y-2" : "flex flex-row"
          } md:flex md:flex-row col-span-1 gap-x-8 justify-between ${
            tableOptions.pillArray ? "xl:justify-end" : ""
          } `}
        >
          {sortOptions && !tableOptions.dontSort && (
            <div className=" min-w-[180px] flex flex-row justify-between">
              <TextField
                className="flex flex-1 flex-grow"
                size={"small"}
                select
                defaultValue={""}
                value={sorting}
                label={ls.getText("Sort By")}
                onChange={(e) => {
                  let tableOptionsIndex = tableOptions.fieldTitles.indexOf(
                    e.target.value
                      .split(" ")
                      .slice(0, e.target.value.split(" ").length - 1)
                      .join(" ")
                  )

                  setSorting({
                    title: tableOptions.fieldIds[tableOptionsIndex],
                    asc:
                      e.target.value.split(" ")[e.target.value.split(" ").length - 1] === "(ASC)"
                        ? true
                        : false,
                  })
                }}
              >
                {sortOptions.map((sortOption, index) => {
                  return (
                    <MenuItem key={sortOption} value={sortOption}>
                      {sortOption}
                    </MenuItem>
                  )
                })}
              </TextField>
            </div>
          )}

          {tableOptions.searchParams && <CVSearchBar search={search} setSearch={setSearch} />}
          {tableOptions.button && (
            <div className="col-span-2 ">
              <Button
                onClick={() => tableOptions.button?.onClick()}
                disableElevation
                variant="contained"
                disabled={tableOptions.button.disabled}
                fullWidth
              >
                {tableOptions.button.title}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        ref={scrollDivRef}
        className="overflow-auto flex flex-col mt-[22px]"
        onScroll={(e) => {
          const target = e.target as HTMLInputElement
          if (
            target.scrollTop !== 0 &&
            target.scrollHeight - Math.ceil(target.scrollTop) <= target.clientHeight * 1.03
          ) {
            onScrollToBottom()
          }
        }}
      >
        <table className="w-full border-separate border-spacing-y-3 drop-shadow">
          <tbody>
            {displayData && displayData.length ? (
              displayData.map((item: TableData, itemIndex: number) => {
                return (
                  <tr
                    key={itemIndex}
                    className={`${tableOptions.onClick ? "hover:cursor-pointer" : ""}`}
                    onClick={() => tableOptions.onClick && tableOptions.onClick(item)}
                  >
                    {tableOptions.fieldIds.map((fieldId, fieldIndex) => {
                      let rounding = ""
                      if (tableOptions.fieldIds.length === 1) {
                        rounding = "rounded-[15px]"
                      } else if (fieldIndex === 0) {
                        rounding =
                          "pt-4 sm:pt-[25px] rounded-tl-[15px] md:rounded-tl-[15px] rounded-tr-[15px] md:rounded-tr-none rounded-bl-none md:rounded-bl-[15px] rounded-br-none md:rounded-br-none"
                      } else if (fieldIndex === tableOptions.fieldIds.length - 1) {
                        rounding =
                          "md:pr-[30px] rounded-tl-none md:rounded-tl-none rounded-tr-none md:rounded-tr-[15px] rounded-bl-[15px] md:rounded-bl-none rounded-br-[15px] md:rounded-br-[15px]"
                      }
                      return (
                        <td
                          key={fieldIndex}
                          className={`p-2 px-4 mx-auto sm:pb-[25px] sm:px-[25px] md:p-0 md:py-[25px] bg-white  block md:table-cell ${rounding}`}
                        >
                          <div
                            className={`md:ml-[30px]
                            flex flex-col md:h-[52px] w-0 min-w-full md:w-full`}
                          >
                            <div className="font-semibold text-base md:text-md text-black md:text-darkCovviGrey self-start truncate">
                              {tableOptions.fieldTitles[fieldIndex]}
                            </div>
                            <div className="font-light text-lg md:text-xl overflow-hidden truncate">
                              {dataToDisplay(fieldId, item[fieldId as keyof TableData])}
                            </div>
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td>
                  <CVSubtitle text={ls.getText("No Data Found")} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
