import { ReactElement, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ArrowBack } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { languageSelector as ls } from "@covvi/language-selector"
import { MobileNav } from "./navigation/MobileNavigation"
import { SideNav } from "./navigation/SideNavigation"

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#C57C46",
    },
    secondary: {
      main: "#d6d6d6",
      contrastText: "#737373",
    },
    error: {
      main: "#D53B3B",
    },
  },
  typography: {
    button: {
      textTransform: "none",
      whiteSpace: "nowrap",
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          fontFamily: "Proxima Nova",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          "& .MuiFormLabel-root.Mui-disabled": {
            WebkitTextFillColor: "#747A87",
          },
          "& .MuiSvgIcon-root.Mui-disabled": {
            color: "#00000000",
          },
          "& .MuiInputBase-input": {
            fontWeight: "normal",
            fontFamily: "Proxima Nova",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "rgba(255,255,255, 0.6)",
            input: {
              "&::placeholder": {
                textOverflow: "ellipsis !important",
                color: "black",
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-containedSecondary": {
            "&:hover": {
              color: "black",
            },
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          paddingLeft: "10",
          paddingRight: "10",
          "& .MuiFormGroup-row": {
            paddingLeft: "10",
            paddingRight: "10",
          },
        },
      },
    },
  },
})

const Layout = ({
  children,
  title,
  subTitle,
}: {
  children?: ReactElement<any, any>
  title?: string
  subTitle?: string
}) => {
  const [customTitle, setCustomTitle] = useState<string>("")
  const [hasBackButton, setHasBackButton] = useState<boolean>(false)
  const location = useLocation()
  const navigation = useNavigate()

  useEffect(() => {
    if (
      location.pathname.split("/").length === 3 &&
      location.pathname.split("/")[1] === "reset_password"
    ) {
      setCustomTitle(ls.getText("Account Management"))
    } else if (title === "Users" && subTitle === "View User" && location.state) {
      setCustomTitle(
        "User - " + location.state.data.first_name + " " + location.state.data.last_name
      )
      setHasBackButton(true)
    } else if (title === "Remote Assist") {
      let split = location.pathname.split("/")
      if (split.length === 2) {
        setCustomTitle(title)
        setHasBackButton(false)
      } else if (split.length === 3 && split[2].length === 6 && !isNaN(Number(split[2]))) {
        setCustomTitle(`Remote Assist Room ${split[2]}`)
        setHasBackButton(true)
      }
    } else if (title === "Hands") {
      if (subTitle === "View Hand") {
        setCustomTitle(`Hand ${location.pathname.split("/")[2]}`)
        setHasBackButton(true)
      } else if (subTitle === "View Hand Config") {
        let split = location.pathname.split("/")
        setCustomTitle(`Hand #${split[2]} - ${decodeURI(split[3])}`)
        setHasBackButton(true)
      }
    } else if (title === "Support Tickets") {
      if (subTitle === "View Ticket") {
        setCustomTitle(`Support Ticket #${location.pathname.split("/")[2]}`)
        setHasBackButton(true)
      }
    } else {
      title && setCustomTitle(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <>
        {title && <MobileNav title={title} />}
        <div
          className={`w-full flex flex-col h-screen content-center justify-start ${
            title !== undefined ? "bg-main" : "sm:bg-backgroundMid xl:bg-background bg-cover"
          }`}
        >
          {title ? (
            <div className="w-full md:w-11/12 md:h-[90%] m-auto flex flex-row content-center min-h-[600px] md:mt-[82px] md:mb-[40px] lg:m-auto h-full">
              <SideNav title={title} />
              <main
                className={`w-full flex flex-col p-2 md:p-10   mt-[72px] md:mt-0 content-center overflow-auto bg-body md:rounded-3xl lg:rounded-r-3xl lg:rounded-l-none no-scrollbar`}
              >
                <div
                  className={`hidden md:flex flex-row justify-between lg:justify-start items-center  mr-0 lg:mx-0 pb-4 lg:pb-6`}
                >
                  <div className="grow lg:grow-0">
                    {hasBackButton ? (
                      <IconButton sx={{ color: "black" }} onClick={() => navigation(-1)}>
                        <ArrowBack />
                      </IconButton>
                    ) : null}
                  </div>

                  <div className={"min-h-[40px] lg:ml-0"}>
                    <h1 className="font-semibold break-words text-center text-2xl lg:mx-0 leading-[40px]">
                      {customTitle ? customTitle.replaceAll("_", " ") : title}
                    </h1>
                  </div>
                  <div className="grow lg:grow-0 h-[40px] lg:hidden"></div>
                </div>
                <div className="flex md:hidden">
                  <h1 className=" mx-auto font-semibold break-words text-center text-2xl lg:mx-0 leading-[40px]">
                    {customTitle ? customTitle.replaceAll("_", " ") : title}
                  </h1>
                </div>
                {children}
              </main>
            </div>
          ) : (
            <main className={"w-full flex flex-col content-center justify-center my-auto"}>
              {children}
            </main>
          )}
        </div>
      </>
    </ThemeProvider>
  )
}

export default Layout
