import { firestore } from "./firebase"
import { doc, updateDoc } from "firebase/firestore"

export const acceptDataConsent = async (uid: string) => {
  return new Promise((resolve, reject) => {
    const userRef = doc(firestore, "Users", uid)
    updateDoc(userRef, {
      dataConsent: new Date(),
    })
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
